import {
  SActionButtonsWrapper,
  SButton
} from "@src/styled-components/form/SButtons";
import { getCookie, setCookie } from "@src/utils/cookies";
import { Modal } from "antd";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const useAppStoreNotification = () => {
  const router = useRouter();
  const [initialized, setInitialized] = useState<boolean>();
  const [isModalOpen, setModalOpen] = useState<boolean>();
  const [utmMedium, setUtmMedium] = useState<string | undefined>();
  const userAgent = navigator.userAgent.toLowerCase();

  const isMobile = /iphone|ipad|ipod|android/g.test(userAgent);

  const isiOS = /iphone|ipad|ipod/g.test(userAgent);

  useEffect(() => {
    const hideAppNotification =
      getCookie("hide-app-notification", document.cookie) === "1" ||
      router.query.hide === "app-notification";

    if (!router.isReady || !isMobile || hideAppNotification) return;

    const utmMediumCookie = getCookie("utm_medium", document.cookie);

    if (router.query.utm_medium) {
      setUtmMedium(router.query.utm_medium as string);
      setInitialized(true);
    } else if (utmMediumCookie) {
      setUtmMedium(utmMediumCookie);
      setInitialized(true);
    } else {
      setInitialized(true);
    }
  }, [router.isReady]);

  const onCancel = () => {
    setCookie("hide-app-notification", "1", 7);
    setModalOpen(false);
  };

  const onOk = () => {
    router.push(
      isiOS
        ? "https://apps.apple.com/de/app/smavesto-einfach-anlegen/id1441091680"
        : "https://play.google.com/store/apps/details?id=com.smavesto&hl=de&gl=US"
    );
    setCookie("hide-app-notification", "1", 7);
  };

  const contextHolder = (
    <Modal
      onCancel={onCancel}
      title="Smavesto gibt es auch als App"
      visible={isModalOpen}
      wrapProps={{ border: 0 }}
      footer={
        <SActionButtonsWrapper>
          <SButton
            style={{ width: "100%" }}
            className="submit"
            type="primary"
            onClick={onOk}
          >
            {isiOS ? "App Store öffnen" : "Google Play Store öffnen"}
          </SButton>

          <SButton
            style={{ width: "100%" }}
            htmlType="submit"
            className="reset"
            onClick={onCancel}
          >
            Auf Website bleiben
          </SButton>
        </SActionButtonsWrapper>
      }
    >
      Entdecken Sie die Vorteile von Smavesto unterwegs - unsere App macht es
      möglich!
    </Modal>
  );

  useEffect(() => {
    if (initialized === true && utmMedium !== "app" && isMobile) {
      setModalOpen(true);
    }
  }, [initialized]);

  return { contextHolder, initialized };
};

export default useAppStoreNotification;
