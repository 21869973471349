import React, { useEffect } from "react";

import PageWrapper from "@components/layout/template/PageWrapper/PageWrapper";
import type { IPageBaseProps } from "@src/interfaces/jsx.interface";

import useIsMaintenanceMode from "@src/hooks/maintenance/useIsMaintenanceMode";
import useAppStoreNotification from "@src/hooks/marketing/useAppStoreNotification";
import { useSupplierAndAffiliateOrigin } from "@src/hooks/onboarding/affiliate/useSupplierAndAffiliateOrigin";
import { useResetRecoilGlobal } from "@src/page-components/onboarding/hooks";
import { LoginForm } from "./LoginForm";
import styles from "./styles.module.less";

const LoginPage: React.FunctionComponent<IPageBaseProps> = ({ style }) => {
  useSupplierAndAffiliateOrigin(true);

  const resetRecoil = useResetRecoilGlobal();

  const { contextHolder } = useAppStoreNotification();

  useEffect(() => {
    resetRecoil();
  }, []);

  useIsMaintenanceMode();

  return (
    <PageWrapper
      title="Anmeldung"
      className={styles["comp-wrapper"]}
      style={style}
    >
      <LoginForm />
      {contextHolder}
    </PageWrapper>
  );
};

export default LoginPage;
