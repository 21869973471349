import LoginPage from "@page-components/public/login";
import type { NextPageContext } from "next";
import { getAdditionalSiteProps } from "..";

export default LoginPage;

export const getServerSideProps = async (context: NextPageContext) => ({
  props: {
    layout: "public",
    name: "Login",
    ...getAdditionalSiteProps(context)
  }
});
